import {
  UnifiedThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';
import { mergeWithCommon } from './Common';

const theme: Partial<UnifiedThemeOptions> = {
  ...{
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              color: '#000000',
              backgroundColor: '#F8F8F8',
              borderRadius: '10px',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: '#FFFFFF',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          // Fix for links in starred entities not using theme link color
          root: {
            '& a': {
              color: '#101010',
              textDecoration: 'none',
            },
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: '#EBE6DC',
            },
          },
        },
      },
      BackstageHeader: {
        styleOverrides: {
          title: () => ({
            color: "#010101",
            fontSize: '1.3rem',
          }),
          subtitle: () => ({
            color: "#000000",
          }),
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(115,100,75,0.4)',
          },
        },
      },
      BackstageHeaderTabs: {
        styleOverrides: {
          defaultTab: {
            fontWeight: 'normal',
            color: '#000000',
          },
          selected: {
            fontWeight: 'bold',
          }
        }
      },
      BackstageBottomLink: {
        styleOverrides: {
          boxTitle: {
            color: '#000000',
          }
        }
      }
    },
  },
  ...{
    palette: {
      ...palettes.light,
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
      primary: {
        main: '#A08C64',
      },
      secondary: {
        main: '#73644B',
      },
      background: {
        default: '#F8F8F8',
        paper: '#ffffff',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#343b58',
        link: '#101010',
      },
      navigation: {
        background: '#EBE6DC',
        indicator: '#73644B',
        color: '#101010',
        selectedColor: '#73644B',
        navItem: {
          hoverBackground: '#EBE6DC',
        },
      },
      text: {
        primary: '#101010',
        secondary: '#343b58',
        disabled: '#9D9D9D',
        hint: '#434343',
      },
    },
  },
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#BEAF93', '#A08C64'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#BEAF93', '#A08C64'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#BEAF93', '#A08C64'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#BEAF93', '#A08C64'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#BEAF93', '#A08C64'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#BEAF93', '#A08C64'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#BEAF93', '#A08C64'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#BEAF93', '#A08C64'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#BEAF93', '#A08C64'], shape: shapes.wave }),
  },
};

export const lightTheme = createUnifiedTheme(mergeWithCommon(theme));
