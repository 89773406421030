import React from 'react';

import {
  Page,
  Header,
  Content,
  ItemCardGrid,
  LinkButton,
  Progress,
  WarningPanel,
  CodeSnippet,
  ItemCardHeader,
} from '@backstage/core-components';
import { Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import { CATALOG_FILTER_EXISTS, CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/esm/useAsync';
import { Entity } from '@backstage/catalog-model';
import { techdocsPlugin } from '@backstage/plugin-techdocs';

// Metadata tag to filter guideline TechDocs
const GUIDELINE_TAG = "guideline";

const upcomingCards: CardContent[] = [
  {
    title: "Security guidelines",
    description: "COMING SOON - The most common practices and guidelines for security at Sandvik, read and consider.",
  }
];

interface CardContent {
  title: string;
  link?: string;
  description: string;
}

const toCard = (cardContent: CardContent) => (
  <Card key={cardContent.title} data-testid={"guidelines-card"}>
    <CardMedia>
      <ItemCardHeader title={cardContent.title} />
    </CardMedia>
    <CardContent>
      <Typography gutterBottom>
        {cardContent.description}
      </Typography>
    </CardContent>
    <CardActions>
      {cardContent.link ?
        <LinkButton to={cardContent.link}> Visit </LinkButton> :
        <LinkButton to="todo" disabled={true}> Coming soon </LinkButton>}
    </CardActions>
  </Card>
)

const Base = (props: React.PropsWithChildren) => (
  <Page themeId="home">
    <Header title="Guidelines" />
    <Content>
      {props.children}
    </Content>
  </Page>
)

export const GuidelinesPage = () => {
  const catalogApi: CatalogApi = useApi(catalogApiRef);
  const getRouteToReaderPageFor = useRouteRef(techdocsPlugin.routes.docRoot);
  const {
    value: entities,
    loading,
    error,
  } = useAsync(async () => {
    return await catalogApi.getEntities({
      // Filter on techdocs with a 'guideline' tag
      filter: {
        'metadata.tags': [GUIDELINE_TAG],
        'metadata.annotations.backstage.io/techdocs-ref': CATALOG_FILTER_EXISTS,
      },
    });
  });

  const toCardContent = (entity: Entity): CardContent => {
    return {
      title: entity.metadata.title ?? "MISSING_TITLE",
      description: entity.metadata.description ?? "MISSING_DESCRIPTION",
      link: getRouteToReaderPageFor({
        namespace:
          entity.metadata.namespace ?? 'default',
        kind: entity.kind,
        name: entity.metadata.name
      }),
    }
  };

  const cards = upcomingCards
    .concat(entities?.items.map(toCardContent) ?? [])
    .sort((e0, e1) => e0.title.localeCompare(e1.title));

  if (loading) {
    return (
      <Base>
        <Progress />
      </Base>
    );
  }

  if (error) {
    return (
      <Base>
        <WarningPanel
          severity="error"
          title="Could not load available documentation."
        >
          <CodeSnippet language="text" text={error.toString()} />
        </WarningPanel>
      </Base >
    )
  }

  return (
    <Base>
      <ItemCardGrid>
        {cards.map(toCard)}
      </ItemCardGrid>
    </Base>
  )
}
