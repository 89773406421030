import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  errorApiRef,
  identityApiRef,
  //discoveryApiRef,
  //fetchApiRef,
  //storageApiRef,
} from '@backstage/core-plugin-api';

//import { UserSettingsStorage } from '@backstage/plugin-user-settings';
import { VisitsWebStorageApi, visitsApiRef } from '@backstage/plugin-home';

//Commented out as not compatible after upgrade of Backstage from "version": "1.20.0" to  "version": "1.27.0-next.0"
//import { TechRadarClient } from './lib/RadarDataClient';
//import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: visitsApiRef,
    deps: {
      identityApi: identityApiRef,
      errorApi: errorApiRef
    },
    factory: ({ identityApi, errorApi }) => VisitsWebStorageApi.create({ identityApi, errorApi }),
  }),
  /* createApiFactory({
      api: storageApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        errorApi: errorApiRef,
        fetchApi: fetchApiRef,
        identityApi: identityApiRef,
      },
      factory: deps => UserSettingsStorage.create(deps),
    }), */
  /*   createApiFactory(
      {
        api: techRadarApiRef,
        deps: { configApi: configApiRef },
        factory: ({ configApi }) => new TechRadarClient(configApi),
      },), */
];
