import { useSearchModal } from '@backstage/plugin-search';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

const KeyboardShortcutSearchToggler: React.FC = () => {
  const { toggleModal } = useSearchModal();

  useHotkeys('/', (event) => {
    event.preventDefault();
    toggleModal();
  });

  return null;
};

export default KeyboardShortcutSearchToggler;