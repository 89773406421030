import {
  HomePageToolkit,
  HomePageStarredEntities,
  HomePageRecentlyVisited,
  HomePageTopVisited,
  FeaturedDocsCard
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import {
  SearchContextProvider,
} from '@backstage/plugin-search-react';
//Still Using MUI 4 as we depend on the @backstage/*-react components
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { tools } from './shared';

const useSearchBarStyles = makeStyles(theme => ({
  input: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: '0px 0px 2px 1px rgba(115, 100, 75, 0.4)',
    WebkitBoxShadow: '0px 0px 2px 1px rgba(115, 100, 75, 0.4)',
  },
  outline: {
    borderStyle: 'none'
  },
  container: {
    marginTop: theme.spacing(8),
  }
}));

export const HomePage = () => {
  const searchBarClasses = useSearchBarStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content className='homepage'>
          <Grid container justifyContent="center">
            <Grid container item xs={12} justifyContent='center' className={searchBarClasses.container}>
              <HomePageSearchBar
                InputProps={{ classes: { root: searchBarClasses.input, notchedOutline: searchBarClasses.outline } }}
                placeholder="Type / to search"
                data-testid="homepage-search-bar"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6} data-testid="homepage-top-visited">
                <HomePageTopVisited />
              </Grid>
              <Grid item xs={12} md={6} data-testid="homepage-recently-visited">
                <HomePageRecentlyVisited />
              </Grid>
            </Grid>
            <Grid container item xs={12} data-testid="homepage-starred-entities">
              <Grid item xs={7}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={5} data-testid="homepage-toolkit">

                <HomePageToolkit tools={tools} />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6} data-testid="homepage-getting-started-card" >
                <FeaturedDocsCard
                  title="Getting Started"
                  filter={{
                    'metadata.name': 'getting-started-with-the-developer-portal',
                  }}

                />
              </Grid>
              <Grid item xs={6} data-testid="homepage-doc-card">
                <FeaturedDocsCard
                  title="Documentation Spotlight"
                  filter={{
                    'metadata.name': 'github-walkthrough',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
