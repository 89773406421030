import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { useApi, configApiRef, identityApiRef } from '@backstage/core-plugin-api';
import { useEffect } from 'react';


export const applicationInsightsReactPlugin = new ReactPlugin();

async function hashUserEntityRef(userEntityRef: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(userEntityRef);
    const hash = await window.crypto.subtle.digest('SHA-256', data);
    return Array.from(new Uint8Array(hash)).map(b => b.toString(16).padStart(2, '0')).join('');
  }

export const useAnalytics = () => {
    const config = useApi(configApiRef);
    const identity = useApi(identityApiRef);

    useEffect(() => {
      var appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: config.getOptionalString('app.applicationInsightsInstrumentationKey'),
          extensions: [applicationInsightsReactPlugin],
          enableAutoRouteTracking: true
        }
      });
      appInsights.loadAppInsights();

      identity.getBackstageIdentity().then(user => {
        hashUserEntityRef(user.userEntityRef).then(hashedUserId => {
          appInsights.context.user.id = hashedUserId;
        });
      });
    }, [config, identity]);
  }
