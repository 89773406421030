import { styled } from '@mui/material/styles';

//Custom Sidebar Divider due to bug https://github.com/backstage/backstage/issues/15427
export const SidebarDivider = styled('hr')(({ theme }) => ({
    height: 1,
    width: '100%',
    background: '#383838',
    border: 'none',
    margin: theme.spacing(1.2, 0),
    visibility: 'hidden',
}));
