import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import { TemplateSandvikLogo } from '../../assets/logos/TemplateSandvikLogo';


const useStyles = makeStyles(() => ({
  path: {
    fill: useTheme().palette.navigation.color
  },
}));

const LogoFull = (props: {
  classes: {
    svg: string;
  };
}) => {
  const { path } = useStyles();

  return (
    <TemplateSandvikLogo classes={{ svg: props.classes.svg, path: path }} />
  );
};

export default LogoFull;
