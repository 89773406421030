
import React from 'react';

export const TemplateSandvikLogoIcon = (props: {
  classes: {
    svg: string;
    path: string;
  };
}) => {
  return (
    <svg
      className={props.classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
    >
      <g>
        <path
          className={props.classes.path}
          d="M853.55,853.56c49.74-49.74,88.43-109.43,113.53-175.14,25.1-65.72,36.05-135.99,32.14-206.23l-263.52,263.52h0l-263.52,263.52c70.24,3.91,140.52-7.04,206.23-32.14,65.72-25.1,125.4-63.79,175.14-113.53h0Z"
        />
        <path
          className={props.classes.path}
          d="M595.75,595.76l50.9-147.72c12.37-35.89,32.75-68.5,59.59-95.35l175.55-175.55c47.56,56.24,82.11,122.3,101.18,193.45l-129.41,129.41c-26.84,26.84-59.45,47.22-95.34,59.59l-147.73,50.9h0l-50.9,147.72c-12.37,35.89-32.74,68.5-59.59,95.34l-129.41,129.41c-71.15-19.06-137.21-53.61-193.45-101.18l175.55-175.55c26.84-26.85,59.45-47.22,95.35-59.59l147.72-50.9h0Z"
        />
      </g>
      <path
        className={props.classes.path}
        d="M404.24,404.25l147.72-50.9c35.89-12.37,68.5-32.75,95.35-59.59l175.55-175.55c-56.24-47.56-122.3-82.11-193.45-101.18l-129.41,129.41c-26.84,26.84-47.22,59.45-59.59,95.34l-50.9,147.73h0l-147.73,50.89c-35.89,12.37-68.5,32.74-95.34,59.59L17.04,629.41c19.06,71.15,53.61,137.21,101.18,193.45l175.55-175.55c26.85-26.84,47.22-59.45,59.59-95.35l50.9-147.72h0Z"
      />
      <path
        className={props.classes.path}
        d="M146.44,146.45c49.74-49.74,109.43-88.43,175.14-113.53C387.3,7.81,457.58-3.14,527.82,.77L264.29,264.3h0L.77,527.82c-3.91-70.24,7.04-140.52,32.14-206.23,25.1-65.72,63.79-125.4,113.53-175.14h0Z"
      />    </svg>
  );
};