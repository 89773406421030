import GitHubIcon from '@mui/icons-material/GitHub';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import PeopleIcon from '@mui/icons-material/People';
import SupportIcon from '@mui/icons-material/Support';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import CloudIcon from '@mui/icons-material/Cloud';
import React from 'react';


// Browse icons here: https://mui.com/material-ui/material-icons/
export const tools = [
  {
    url: 'https://github.com/enterprises/sandvik',
    label: 'GitHub',
    icon: <GitHubIcon />,
  },
  {
    url: 'https://dev.azure.com/sandvik/',
    label: 'Azure DevOps',
    icon: <AllInclusiveIcon />,
  },
  {
    url: 'https://serviceportal.sandvik.com/sp',
    label: 'Service Portal',
    icon: <SupportIcon />,
  },
  {
    url: 'https://teams.microsoft.com/l/team/19%3AeokB2OUXE_92_Aa84SOlXxdtKEK6Mt1JkPPGz2cJMTU1%40thread.tacv2/conversations?groupId=5cc1fd98-88a9-4316-a57f-8cb876048481&tenantId=e11cbe9c-f680-44b9-9d42-d705f740b888',
    label: 'Dev Community',
    icon: <PeopleIcon />,
  },
  {
    url: 'https://brand.sandvik',
    label: 'Brand Portal',
    icon: <ColorLensIcon />,
  },
  {
    url: 'https://portal.ccc.sandvik.com',
    label: 'Cloud Platform Portal',
    icon: <CloudIcon />,
  }
];
