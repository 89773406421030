import {
  UnifiedThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';
import { mergeWithCommon } from './Common';

const theme: Partial<UnifiedThemeOptions> = {
  ...{
    components: {
      MuiListItem: {
        styleOverrides: {
          // Expected to only affect starred, top, and recent entities lists
          root: {
            '& a': {
              color: '#dddddd',
              textDecoration: 'none'
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(115,100,75,0.8)',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: '#242424',
          },
        },
      },
      BackstageHeader: {
        styleOverrides: {
          title: () => ({
            color: "#FFFFFF",
            fontSize: '1.3rem',
          }),
          subtitle: () => ({
            color: "#FFFFFF",
          }),
        },
      },
      BackstageSidebar: {
        styleOverrides: {
          drawer: {
            borderRight: '#BEAF93',
            borderRightWidth: 'medium',
            borderRightStyle: 'groove',
          }
        }
      },
      BackstageInfoCard: {
        styleOverrides: {
          header: {
            background: '#323232'
          }
        }
      },
      BackstageHeaderTabs: {
        styleOverrides: {
          defaultTab: {
            fontWeight: 'normal',
            color: '#FFFFFF',
          },
          selected: {
            fontWeight: 'bold',
          }
        }
      },
      BackstageBottomLink: {
        styleOverrides: {
          boxTitle: {
            color: '#FFFFFF',
          }
        }
      },
    }
  },
  ...{
    palette: {
      ...palettes.dark,
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
      primary: {
        main: '#BEAF93',
      },
      secondary: {
        main: '#F54B00',
      },
      background: {
        default: '#242424',
        paper: '#323232',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#343b58',
        link: '#565a6e',
      },
      navigation: {
        background: '#242424',
        indicator: '#BEAF93',
        color: '#FFFFFF',
        selectedColor: '#BEAF93',
        navItem: {
          hoverBackground: '#242424',
        },
      },
    },
  },
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#010101', '#73644b'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#010101', '#73644b'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#010101', '#73644b'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#010101', '#73644b'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#010101', '#73644b'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#010101', '#73644b'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#010101', '#73644b'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#010101', '#73644b'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#010101', '#73644b'], shape: shapes.wave }),
  },
}

export const darkTheme = createUnifiedTheme(mergeWithCommon(theme));
